"use client";
import { signIn } from 'next-auth/react'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Button } from "@/components/ui/button";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import Link from "next/link";
import { ButtonWantSignUp } from "@/components/ButtonWantSignUp";
import { useRouter } from 'next/navigation';

const formLoginSchema = z.object({
  email: z.string().min(1, { message: 'Necessário preencher e-mail ou usuário' }),
  password: z.string().min(1, { message: 'Necessário preencher a senha' })
})

export function FormLogin() {
  const router = useRouter();

  async function onSubmit(values: z.infer<typeof formLoginSchema>) {
    const response = await signIn('credentials', {
      email: values.email,
      password: values.password,
      redirect: false
    })

    if (response?.ok) {
      window.location.href = '/profile';
    } else {
      form.setError('email', {
        message: response?.error ?? 'Usuário ou senha incorretos, verifique os dados'
      })
    }
  };

  const form = useForm<z.infer<typeof formLoginSchema>>({
    resolver: zodResolver(formLoginSchema)
  });

  return (
    <div className="max-w-screen-sm w-full">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <span>Entrar</span>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className='flex-1'>
                <FormLabel>E-mail ou nome de usuário</FormLabel>
                <FormControl>
                  <Input placeholder="insira seu e-mail, ou nome de usuário para fazer login" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className='flex-1'>
                <FormLabel>Senha</FormLabel>
                <FormControl>
                  <Input type='password' placeholder="insira sua senha para fazer login" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />


          <Link href='/password-reset' className="flex justify-end">
            <Button
              type="button"
              variant='link'
              className="text-zinc-300"
            >
              Esqueceu sua senha?
            </Button>
          </Link>

          <Button
            type="submit"
            className="w-full bg-fs-red hover:bg-fs-red-700"
            isloading={form.formState.isSubmitting}
          >
            Entrar
          </Button>

          <div className="flex space-x-4 items-center">
            <span className="text-zinc-300">
              ainda não é cadastrado?
            </span>
            <ButtonWantSignUp btnLabel='Cadastre-se agora' />
          </div>

        </form>
      </Form>
    </div>
  )
}